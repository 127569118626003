import { useCallback, useEffect, useState } from "react";
import ContentHeader from "../../../../components/ContentHeader/ContentHeader";
import Table from "../../../../components/Table/Table";
import {
  conenctorRemoteColumnOrder,
  connectorRemoteColumns,
  operationRemoteColumnOrder,
  operationRemoteColumns,
} from "./remoteData";
import Paging from "../../../../components/Paging/Paging";
import RemoteButtonGroup from "./RemoteButtonGroup";
import RemoteSubtable from "./RemoteSubtable";
import Toast from "../../../../components/Toast/Toast";
import Popup from "../../../../components/Popup/Popup";
import AvailabilityTable from "../../../../components/AvailabilityTable/AvailabilityTable";
import { useStore } from "../../../../app/stores/store";
import { observer } from "mobx-react-lite";
import MySpinner from "../../../../components/Spinner/MySpinner";
import { downloadCSV } from "../../../../utils/csv/downloadCsv";
import { ConnectorInCharger } from "../../../../app/models/connector";

const OperationsRemote = observer(() => {
  const { chargerStore, connectorStore, signalRStore } = useStore();
  const [selected, setSelected] = useState<number | null>(null);
  const [pagination, setPagination] = useState({ page: 1, perPage: 10 });
  const [buttonTypePopup, setButtonTypePopup] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [loading, setLoading] = useState(false);
  const [toastVisible, setToastVisible] = useState(false);
  const [changeAvailabilityStatus, setChangeAvailabilityStatus] =
    useState(false);
  const [availabilitySelected, setAvailabilitySelected] = useState<
    number | null
  >(null);

  useEffect(() => {
    chargerStore.getChargersRemoteManagement(
      pagination.page,
      pagination.perPage
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination]);

  const handleExpand = (chargerId: number) => {
    const handleGetConnectorAndSignalRConnection = async () => {
      const connectors = await connectorStore.getConnectorByChargerId(chargerId);
      connectors && handleSignalRConnectors(connectors);
    }
    connectorStore.clearConnectorsInCharger();
    signalRStore.closeConnection();
    chargerId && handleGetConnectorAndSignalRConnection();
  };

  const handlePageChange = useCallback(
    (newPage: number, newPerPage: number) => {
      setPagination({ page: newPage, perPage: newPerPage });
    },
    []
  );

  const handleClosePopup = () => {
    if (buttonTypePopup === "Availability") {
      connectorStore.clearConnectorsForPopupInCharger();
      setAvailabilitySelected(null);
    }
    setShowPopup(false);
  };

  const resetPopupText = () => {
    return (
      <div className="flex flex-col text-darkGreen justify-center items-center font-semibold text-[24px]">
        {buttonTypePopup === "Availability" || buttonTypePopup === "Unlock" ? (
          <div className="flex flex-col gap-6 items-center">
            <div className="flex flex-col gap-4 items-center">
              {buttonTypePopup === "Availability"
                ? "Do you want to Change Availability?"
                : "Do you want to Unlock Connector?"}
              <div className="flex gap-3">
                <img src="/assets/svg/popup/greenDot.svg" alt="" />
                <p className="text-[16px] font-normal text-[#ADADAD]">
                  Charger ID:{" "}
                  {
                    chargerStore.chargersRemote?.find(
                      (ch) => ch.id === selected
                    )?.ocppChargerId
                  }
                </p>
              </div>
            </div>

            <AvailabilityTable
              data={connectorStore.connectorsForPopupInCharger!.map((conn) => ({
                id: conn.id,
                connectorName: conn.connectorName,
              }))}
              selected={availabilitySelected!}
              handleSelection={(id: number) => setAvailabilitySelected(id)}
            />

            {buttonTypePopup === "Availability" && (
              <div className="w-full flex flex-row items-center justify-end gap-4">
                <div className="text-[14px]">Available</div>
                <label className="relative inline-flex items-end cursor-pointer">
                  <input
                    type="checkbox"
                    className="sr-only peer"
                    checked={changeAvailabilityStatus}
                    onChange={(e) => {
                      e.stopPropagation();
                      setChangeAvailabilityStatus((prev) => !prev);
                    }}
                  />
                  <div className="w-[60px] h-[30px] bg-input-border peer-focus:outline-none rounded-full peer peer-checked:bg-[#80C042] after:content-[''] after:absolute after:top-[3px] after:left-[4.4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-[24px] after:w-[24px] after:transition-all after:duration-500 peer-checked:after:translate-x-[28px]"></div>
                </label>
              </div>
            )}
          </div>
        ) : (
          <>
            {buttonTypePopup === "Reset"
              ? "Please confirm reset of the charger"
              : "Please confirm emergency reset of the charger"}
            <div className="flex gap-3">
              <img src="/assets/svg/popup/greenDot.svg" alt="" />
              <p className="text-[16px] font-normal text-[#ADADAD]">
                Charger ID:{" "}
                {
                  chargerStore.chargersRemote?.find((ch) => ch.id === selected)
                    ?.ocppChargerId
                }
              </p>
            </div>
          </>
        )}
      </div>
    );
  };

  const handleSaveChanges = async () => {
    setShowPopup(false);
    setLoading(true);
    await signalRStore.startConnection("/hubs/operations");
    if (buttonTypePopup === "Reset" || buttonTypePopup === "Emergency") {
      await signalRStore.subscribeOnRemoteAction(
        "SubscribeToReset",
        {
          chargerId: selected!,
          isHardReset: buttonTypePopup === "Reset" ? false : true,
        },
        () => console.log("Subscription successful."),
        (err: any) => console.error("Subscription error:", err)
      );
      signalRStore.listenRemoteAction("chargerReset", () => {
        console.log("Signal came");
        setToastVisible(true);
        clearAll();
      });
    } else if (availabilitySelected) {
      if (buttonTypePopup === "Availability") {
        await signalRStore.subscribeOnRemoteAction(
          "SubscribeToChangeAvailability",
          {
            chargerId: selected!,
            connectorId: availabilitySelected!,
            type: changeAvailabilityStatus ? 1 : 0,
          },
          () => console.log("Subscription successful."),
          (err: any) => console.error("Subscription error:", err)
        );
        signalRStore.listenRemoteAction("availabilityChanged", () => {
          console.log("Signal came");
          setToastVisible(true);
          clearAll();
        });
      } else if (buttonTypePopup === "Unlock") {
        await signalRStore.subscribeOnRemoteAction(
          "SubscribeToUnlockConnector",
          availabilitySelected,
          () => console.log("Subscription successful."),
          (err: any) => console.error("Subscription error:", err)
        );
        signalRStore.listenRemoteAction("connectorUnlocked", () => {
          console.log("Signal came");
          setToastVisible(true);
          clearAll();
        });
      }
    } else {
      setLoading(false);
      clearAll();
    }
  };

  const handleSignalRConnectors = async (connectors: ConnectorInCharger[]) => {
    await signalRStore.startConnection("/hubs/connectorStatus");

    const connectorIds = connectors.map((connector) => connector.id);
    await signalRStore.subscribeOnRemoteAction(
      "Subscribe",
      connectorIds,
      () => console.log("Subscribed Id's: " + connectorIds),
      (err: any) => console.error("Error while subscribing", err)
    );

    signalRStore.listenRemoteAction("updateConnectorStatus", (updateConnectorStatusSignalRDto) => {
      console.log(`Status for connector ${updateConnectorStatusSignalRDto.connectorId}: ${updateConnectorStatusSignalRDto.status}`);
      let connectorsCopy = [...connectorStore.connectorsInCharger || []];
      connectorsCopy.forEach(connector => {
        if (connector.id === updateConnectorStatusSignalRDto.connectorId)
          connector.connectorStatus = updateConnectorStatusSignalRDto.status;
      });
      connectorStore.setConnectorsInCharger(connectorsCopy);
    });
  }

  const clearAll = async () => {
    setLoading(false);
    setSelected(null);
    setAvailabilitySelected(null);
    await signalRStore.closeConnection();
  };

  const handleReset = () => {
    setButtonTypePopup("Reset");
    setShowPopup(true);
  };
  const handleEmergyencyReset = () => {
    setButtonTypePopup("Emergency");
    setShowPopup(true);
  };
  const handleAvailability = async () => {
    selected &&
      (await connectorStore.getConnectorForPopupByChargerId(selected));
    setButtonTypePopup("Availability");
    setAvailabilitySelected(null);
    setShowPopup(true);
  };
  const handleUnlock = async () => {
    selected &&
      (await connectorStore.getConnectorForPopupByChargerId(selected));
    setButtonTypePopup("Unlock");
    setAvailabilitySelected(null);
    setShowPopup(true);
  };

  const downloadChargerLog = async () => {
    setLoading(true);
    const occpChargerId: string | undefined = chargerStore.chargersRemote?.find(ch => ch.id === selected)?.ocppChargerId
    occpChargerId && downloadCSV(await chargerStore.getChargerLog({ id: occpChargerId }), occpChargerId + "_logs");
    setLoading(false);
  };

  return (
    <div className="flex flex-col gap-4 items-stretch h-full">
      <ContentHeader group="Operations" subgroup="Remote Management">
        <button
          onClick={downloadChargerLog}
          disabled={!selected}
          className={`w-[186px] h-[40px] px-[9px] flex gap-2 justify-center items-center text-[14px] font-medium border-2 rounded-10 ${selected
            ? "border-light-green bg-light-green text-white"
            : "text-lightGray border-lightGray"
            }`}
        >
          <img
            src={
              selected
                ? "/assets/svg/download/downloadWhite.svg"
                : "/assets/svg/download/downloadGray.svg"
            }
            alt=""
          />
          Detailed charger log
        </button>
      </ContentHeader>
      <div className="flex flex-col flex-grow">
        <div className="flex-grow overflow-y-auto bg-white rounded-t-[10px] pt-6 pb-5 px-6">
          <div className="flex flex-col gap-7">
            <RemoteButtonGroup
              selected={selected}
              handleReset={handleReset}
              handleEmergyencyReset={handleEmergyencyReset}
              handleAvailability={handleAvailability}
              handleUnlock={handleUnlock}
            />
            <Table
              data={chargerStore.chargersRemote || []}
              // data={dummyDataSession}
              columns={operationRemoteColumns}
              columnOrder={operationRemoteColumnOrder}
              selected={selected!}
              handleSelection={(id: number) =>
                setSelected(selected === id ? null : id)
              }
              expandable={true}
              handleExpand={handleExpand}
            >
              <RemoteSubtable
                columns={connectorRemoteColumns}
                columnOrder={conenctorRemoteColumnOrder}
                data={connectorStore.connectorsInCharger || []}
              />
            </Table>
          </div>
        </div>

        <Paging
          tableSize={chargerStore.chargersRemoteCount}
          pageChangeFunc={handlePageChange}
        />
      </div>
      <Toast
        text={
          buttonTypePopup === "Reset"
            ? "Charger reset request sent successfully!"
            : buttonTypePopup === "Emergency"
              ? "Charger emergency reset request sent successfully!"
              : buttonTypePopup === "Availability"
                ? "Connector availability is successfully changed!"
                : "Connector successfully unlocked!"
        }
        visible={toastVisible}
        onClose={() => setToastVisible(false)}
        positive={true}
      />
      {showPopup && (
        <Popup
          mainText={resetPopupText()}
          confirmText="Confirm"
          closeText="Close"
          confirmFunction={handleSaveChanges}
          closeFunction={handleClosePopup}
        />
      )}
      {loading && <MySpinner />}
    </div>
  );
});

export default OperationsRemote;
