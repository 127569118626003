import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { ConnectorInCharger, OnboardingConnector } from "../models/connector";

export default class ConnectorStore {
    connectorsInCharger: ConnectorInCharger[] | undefined;
    connectorsForPopupInCharger: ConnectorInCharger[] | undefined;

    constructor() {
        makeAutoObservable(this);
    };

    getConnectorByChargerId = async (chargerId: number) => {
        try {
            const result = await agent.Connector.getConnectorsByChargerId(chargerId);
            runInAction(() => {
                this.connectorsInCharger = result;
            });
            return result;
        } catch (error) {
            console.log("Error fetching connectors for charger: ", error);
            return [];
        }
    };

    getOnboardingConnectorByChargerId = async (chargerId: number) => {
        try {
            const result = await agent.Connector.getOnboardingConnectorsByChargerId(chargerId);
            return result as OnboardingConnector[];
        } catch (error) {
            console.log("Error fetching onboarding connectors for charger: ", error)
            return []
        }
    };

    getConnectorForPopupByChargerId = async (chargerId: number) => {
        try {
            const result = await agent.Connector.getConnectorsByChargerId(chargerId);
            runInAction(() => {
                this.connectorsForPopupInCharger = result;
            });
        } catch (error) {
            console.log("Error fetching connectors in charger for popup: ", error)
        }
    };

    updateConnectorOnboarding = async (connector: OnboardingConnector) => {
        try {
            await agent.Connector.updateConnectorOnboarding(connector);
        } catch (err) {
            console.log("Error while updating onboarding connectors: ", err);
        }
    }

    clearConnectorsInCharger = () => {
        runInAction(() => {
            this.connectorsInCharger = undefined;
        });
    }

    setConnectorsInCharger = (connectors: ConnectorInCharger[]) => {
        runInAction(() => {
            this.connectorsInCharger = connectors;
        });
    }

    clearConnectorsForPopupInCharger = () => {
        runInAction(() => {
            this.connectorsForPopupInCharger = undefined;
        });
    }
}